<template>
	<div>
		<ul class="menu-nav">
			<template v-for="(data, index) in Menu">
				<template
					v-if="
						(data.visible && Array.isArray(data.visible) && data.visible.includes(userType)) ||
						isMaster == 1
					"
				>
					<template v-if="data.child">
						<li
							aria-haspopup="true"
							:key="index"
							data-menu-toggle="hover"
							class="menu-item menu-item-submenu"
							:class="{
								'menu-item-open menu-item-open-active': isRouteActive(data.name),
							}"
						>
							<a href="#" class="menu-link menu-toggle">
								<span class="menu-svg-icon mr-4">
									<!-- <inline-svg :src="$assetURL(data.icon)" /> -->
									<inline-svg fill="#fff" style="height: 25px; width: 25px" :src="data.icon" />
								</span>
								<span class="menu-text">{{ data.text }}</span>
								<i class="menu-arrow"></i>
							</a>
							<div class="menu-submenu">
								<span class="menu-arrow"></span>
								<ul class="menu-subnav">
									<router-link
										v-for="(submenu, submenu_index) in data.submenu"
										:key="`${index}-${submenu_index}`"
										:to="{
											name: submenu.name,
											query: {
												...submenu.query /* t: new Date().getTime(), user_filter: is_staff ? is_staff : null */,
											},
										}"
										v-slot="{ href, navigate, isActive, isExactActive }"
										custom
									>
										<li
											aria-haspopup="true"
											data-menu-toggle="hover"
											class="menu-item"
											:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
										>
											<a :href="href" class="menu-link" @click="navigate">
												<i class="menu-bullet menu-bullet-dot">
													<span></span>
												</i>
												<span class="menu-text">{{ submenu.text }}</span>
											</a>
										</li>
									</router-link>
								</ul>
							</div>
						</li>
					</template>
					<template v-else>
						<router-link
							v-if="getPermission(data.permission)"
							:key="index"
							:to="{
								name: data.name,
								query: {
									...data.query,
								} /* { t: new Date().getTime(), user_filter: is_staff ? is_staff : null } */,
							}"
							v-slot="{ href, navigate, isActive, isExactActive }"
							:class="{
								'menu-item-active': isRouteActive(data.name),
							}"
						>
							<li
								aria-haspopup="true"
								data-menu-toggle="hover"
								class="menu-item"
								:class="{
									'menu-item-active': isRouteActive(data.name),
									/* 'disable-link': disableLink(row.menu), */
								}"
							>
								<v-tooltip :disabled="disableTooltip" right contentClass="custom-right-tooltip">
									<template #activator="{ on, attrs }">
										<a
											:id="data.name"
											v-on="on"
											v-bind="attrs"
											:href="href"
											class="menu-link"
											@click="navigate"
										>
											<span class="menu-svg-icon mr-4">
												<!--begin::Svg Icon-->
												<inline-svg :src="$assetURL(data.icon)" />
												<!-- <inline-svg fill="#fff" style="height: 25px; width: 25px" :src="data.icon" /> -->
												<!--end::Svg Icon-->
											</span>
											<span class="menu-text"
												>{{ data.text }} <v-spacer></v-spacer>
												<span v-if="data.shortcut && shortcutActive">({{ data.shortcut }})</span>
											</span>
										</a>
									</template>
									<span>{{ data.text }}</span>
								</v-tooltip>
							</li>
						</router-link>
					</template>
				</template>
			</template>
		</ul>
	</div>
</template>

<script>
import { getCurrentUser } from "@/core/services/jwt.service";
import { isEmpty } from "lodash";
import { mapGetters } from "vuex";

let altStatus = false;
let _This;

/**
Add more if you want
@example
****** [<ASCII code of character>, <Menu.name>] ******
@description
1. <ASCII code of character> : ASCII code which should be pressed after Alt
2. <Menu.name> : Computed property Menu (Aside menu) Menu's name value.
*/

const codeMap = new Map([
	[65, "claims"], // 'A'
	[67, "customer"], // 'C'
	[68, "domain"], // 'D'
	[73, "invoice"], // 'I'
	[76, "leads"], // 'L'
	[77, "meeting"], // 'M'
	[79, "contract"], // 'O'
	[69, "seo"], // 'E'
	[84, "task"], // 'T'
	[87, "software"], // 'W'
	[80, "project"], // P
	[84, "task"], // T
	[86, "leave"], // V
	[83, "ssl"], // S
]);

document.addEventListener("keyup", (evt) => {
	if (evt.key == "Alt" || evt.which == 18) {
		evt.preventDefault();
		_This.shortcutActive = !altStatus;
		altStatus = !altStatus;
	}

	const id = codeMap.get(evt.keyCode) || codeMap.get(evt.which);
	if (altStatus && id) {
		const menu_element = document.getElementById(id);
		if (menu_element) menu_element.click();
		_This.shortcutActive = !altStatus;
		altStatus = !altStatus;
	}
});

document.addEventListener("keydown", (evt) => {
	if (
		(evt.code === "Comma" || evt.key === "," || evt.keyCode === 188 || evt.which === 188) &&
		evt.ctrlKey
	) {
		_This.$router.push({ name: "setting", query: { tab: "personal_setting" } });
	}
});

export default {
	name: "KTMenu",
	data() {
		return {
			LoginUser: null,
			shortcutActive: false,
		};
	},
	mounted() {
		this.showToolTip = this.sideMenu == "min" ? false : true;
	},
	beforeMount() {
		this.LoginUser = getCurrentUser();
		_This = this;
	},
	methods: {
		hasActiveChildren(match) {
			return this.$route["path"].indexOf(match) !== -1;
		},
		isRouteActive(match) {
			if (isEmpty(this.$route.meta) === false) {
				return this.$route.meta.menuActive === match;
			}
			return false;
		},
		isChildActive(row) {
			let isActive = false;
			for (let i = 0; i < row.length; i++) {
				isActive = this.isRouteActive(row[i].name);
				if (isActive) {
					return isActive;
				}
			}
			return isActive;
		},
	},
	computed: {
		...mapGetters(["currentUser", "userType", "isMaster", "sideMenu"]),
		disableTooltip: function () {
			return this.sideMenu == "min" ? false : true;
		},
		Menu: {
			/* Krishn */
			get: function () {
				const user = getCurrentUser();
				const role_id = user.id;
				const users_type = user.users_type;

				function RoleId() {
					if (users_type == "accounts") {
						return null;
					} else {
						return role_id;
					}
				}

				return [
					{
						permission: "dashboard:view",
						name: "dashboard",
						text: "Dashboard",
						icon: "media/custom-svg/dashboard.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: null,
						visible: ["seo", "sales", "accounts"],
					},
					{
						permission: "lead:view",
						name: "leads",
						text: "Leads",
						icon: "media/custom-svg/report.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "L",
						visible: ["sales"],
					},
					{
						permission: "customer:view",
						name: "customer",
						text: "Customers",
						icon: "media/custom-svg/member.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "C",
						visible: ["sales", "accounts"],
					},
					{
						permission: "task:view",
						name: "task",
						text: "Task",
						icon: "media/menu-icon/task.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "T",
						visible: [],
					},
					{
						permission: "personal:view",
						name: "personal",
						text: "Personal Task",
						icon: "media/menu-icon/task.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: true,
						submenu: [
							{
								name: "my-day",
								text: "My Day",
								query: { t: new Date().getTime(), user_filter: RoleId() },
							},
							{
								name: "next-days",
								text: "Next 7 Days",
								query: { t: new Date().getTime(), user_filter: RoleId() },
							},
							{
								name: "all-task",
								text: "All My Task",
								query: { t: new Date().getTime(), user_filter: RoleId() },
							},
							{
								name: "my-calendar",
								text: "My Calendar",
								query: { t: new Date().getTime(), user_filter: RoleId() },
							},
						],
						shortcut: null,
						visible: [],
					},
					{
						permission: "meeting:view",
						name: "meeting",
						text: "Meeting",
						icon: "media/menu-icon/meeting.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "M",
						visible: ["sales", "seo"],
					},
					{
						permission: "contract:view",
						name: "contract",
						text: "Contracts",
						icon: "media/custom-svg/recurring.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "O",
						visible: ["accounts", "sales"],
					},
					{
						permission: "project:view",
						name: "project",
						text: "Projects",
						icon: "media/custom-svg/inventory.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "P",
						visible: ["accounts", "sales"],
					},
					{
						permission: "claim:view",
						name: "claims",
						text: "Claims",
						icon: "media/menu-icon/claims.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "A",
						visible: ["sales", "accounts"],
					},
					{
						permission: "invoice:view",
						name: "invoice",
						text: "Invoice",
						icon: "media/custom-svg/recurring.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "I",
						visible: ["accounts"],
					},
					{
						permission: "seo:view",
						name: "seo",
						text: "Seo Project",
						icon: "media/custom-svg/project.svg",
						query: {
							t: new Date().getTime(),
							seo_team: users_type == "seo" ? RoleId() : null /* users_type: seo */,
							user_filter: users_type == "sales" ? RoleId() : null /* users_type: sales */,
						},
						child: false,
						shortcut: "E",
						visible: ["seo", "sales", "accounts"],
					},
					{
						name: "customerSeo",
						text: "Projects",
						icon: "media/custom-svg/project.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: null,
						visible: ["customers"],
					},
					{
						permission: "seo:view",
						name: "member",
						text: "Seo Reporting",
						icon: "media/custom-svg/project.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: null,
						visible: [],
					},
					{
						name: "software",
						text: "Software",
						icon: "media/custom-svg/software.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "W",
						visible: [],
					},
					{
						name: "domain",
						text: "Domain",
						icon: "media/custom-svg/domain.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "D",
						visible: ["seo"],
					},
					{
						name: "ssl",
						text: "SSL",
						icon: "media/custom-svg/ssl.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "S",
						visible: ["seo"],
					},
					{
						permission: "leave:view",
						name: "leave",
						text: "Leave",
						icon: "media/menu-icon/meeting.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: "V",
						visible: ["sales", "accounts"],
					},
					{
						name: "taskactivity",
						text: "Activity Log",
						icon: "media/custom-svg/cancel.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						visible: ["seo", "sales", "accounts"],
					},
					{
						name: "viewDashboard",
						text: "View",
						icon: "media/custom-svg/cancel.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						visible: ["seo"],
					},
					{
						name: "ranking",
						text: "Ranking",
						icon: "media/custom-svg/project.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: null,
						visible: ["seo"],
					},
					{
						name: "excel",
						text: "Excel File",
						icon: "media/menu-icon/task.svg",
						query: { t: new Date().getTime(), user_filter: RoleId() },
						child: false,
						shortcut: null,
						visible: [],
					},
				];
			},
		},
	},
};
</script>
