var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.Menu),function(data,index){return [(
					(data.visible && Array.isArray(data.visible) && data.visible.includes(_vm.userType)) ||
					_vm.isMaster == 1
				)?[(data.child)?[_c('li',{key:index,staticClass:"menu-item menu-item-submenu",class:{
							'menu-item-open menu-item-open-active': _vm.isRouteActive(data.name),
						},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{staticStyle:{"height":"25px","width":"25px"},attrs:{"fill":"#fff","src":data.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(data.text))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},_vm._l((data.submenu),function(submenu,submenu_index){return _c('router-link',{key:`${index}-${submenu_index}`,attrs:{"to":{
										name: submenu.name,
										query: {
											...submenu.query /* t: new Date().getTime(), user_filter: is_staff ? is_staff : null */,
										},
									},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active', isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(submenu.text))])])])]}}],null,true)})}),1)])])]:[(_vm.getPermission(data.permission))?_c('router-link',{key:index,class:{
							'menu-item-active': _vm.isRouteActive(data.name),
						},attrs:{"to":{
							name: data.name,
							query: {
								...data.query,
							} /* { t: new Date().getTime(), user_filter: is_staff ? is_staff : null } */,
						}},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:{
								'menu-item-active': _vm.isRouteActive(data.name),
								/* 'disable-link': disableLink(row.menu), */
							},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('v-tooltip',{attrs:{"disabled":_vm.disableTooltip,"right":"","contentClass":"custom-right-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"menu-link",attrs:{"id":data.name,"href":href},on:{"click":navigate}},'a',attrs,false),on),[_c('span',{staticClass:"menu-svg-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL(data.icon)}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(data.text)+" "),_c('v-spacer'),(data.shortcut && _vm.shortcutActive)?_c('span',[_vm._v("("+_vm._s(data.shortcut)+")")]):_vm._e()],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.text))])])],1)]}}],null,true)}):_vm._e()]]:_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }